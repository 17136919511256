import ReactFacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import api from "api";
import { useEffect, useState } from "react";
import {
  ConnectType,
  useWallet,
  WalletStatus,
} from "@terra-money/wallet-provider";
import { LoginType, Chain, WALLET_PROVIDER } from "./game-interface";
import { toast } from "react-toastify";
import { signInWithApple, signInWithGoogle } from "service/firebase";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import EmailConnector from "component/email-connector";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "service/useWindowSize";
import Config from "Config";
import dayjs from "dayjs";
import utils from "service/utils";
import keplrService from "component/keplr";
import web3authService from "service/web3authService";
export default function Home({
  setShowLogin,
  maintainMessage,
  version,
}: {
  maintainMessage: string;
  setShowLogin: Function;
  version: number;
}) {
  const [isMetaMask, setIsMetaMask] = useState(true);
  const [isKeplr, setIsKeplr] = useState(true);
  const [isTerra, setIsTerra] = useState(false);
  const [loading, setLoading] = useState(false);
  const [panel, setPanel] = useState<"signin" | "signup" | "">("");
  const [isEmailClick, setIsEmailClick] = useState(false);
  const modals = useSelector((state: RootState) => state.modals);
  const { t } = useTranslation();
  const size = useWindowSize();
  // @ts-ignore
  const sso_version = window.getVersion();
  // @ts-ignore
  const date = new Date(window.getCountDownTime() ?? "2022-08-25T00:00:00Z");
  const [text, setText] = useState<any>(utils.getCountdownText(date));
  const [expire, setExpire] = useState(true);

  useEffect(() => {
    if (date <= new Date()) {
      setExpire(true);
    } else {
      setExpire(false);
    }
  }, []);

  useEffect(() => {
    let timer: any = null;
    if (!expire) {
      timer = setInterval(() => {
        setText(utils.getCountdownText(date));
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [expire]);

  useEffect(() => {
    let component = document.getElementById("auto-scale");
    if (!component) return;
    if (size.height < 800) {
      component.style.transform = `scale(${size.height / 800})`;
    } else {
      component.style.transform = `scale(1)`;
    }
    // @ts-ignore
  }, [size.height]);

  const { status, availableInstallTypes, install, connect, signBytes } =
    useWallet();

  useEffect(() => {
    if (
      //@ts-ignore
      typeof window.ethereum !== "undefined" ||
      //@ts-ignore
      typeof window.web3 !== "undefined"
    ) {
      setIsMetaMask(true);
    } else {
      setIsMetaMask(false);
    }
    if (
      // @ts-ignore
      window.keplr
    ) {
      setIsKeplr(true);
    } else {
      setIsKeplr(false);
    }
  }, []);

  useEffect(() => {
    if (
      status === WalletStatus.WALLET_NOT_CONNECTED &&
      availableInstallTypes.includes(ConnectType.EXTENSION)
    ) {
      setIsTerra(false);
    } else {
      setIsTerra(true);
    }
  }, [status]);

  async function onMetaMaskClick(type: LoginType) {
    if (!isMetaMask) {
      window.open(
        "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
        "_blank"
      );
      return;
    }
    sessionStorage.setItem("current_login_type", LoginType[type]);
    let chain;
    switch (type) {
      case LoginType.BSC:
        chain = Chain.BSC;
        break;
      case LoginType.Avalanche:
        chain = Chain.Avalanche;
        break;
      case LoginType.Okex:
        chain = Chain.Okex;
        break;
      default:
        alert("invalid chain");
        return;
    }
    // @ts-ignore
    await window.checkNetwork(chain);
    try {
      setLoading(true);
      await api.login(type);
      console.log("hide login");
      setShowLogin(false);
    } catch (error: any) {
      if (error.message) {
        toast(t(error.message));
      }
    } finally {
      setLoading(false);
    }
  }

  async function onKeplrClick(type: LoginType) {
    // @ts-ignore
    if (!window.keplr) {
      window.open(
        "https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?hl=en",
        "_blank"
      );
      return;
    }
    sessionStorage.setItem("current_login_type", LoginType[type]);
    let chain;
    switch (type) {
      case LoginType.Aura:
        chain = Chain.Aura;
        break;
      default:
        alert("invalid chain");
        return;
    }
    await keplrService.connect(WALLET_PROVIDER.KEPLR);
    console.log("start check network");
    // @ts-ignore
    await window.checkNetwork(chain);
    try {
      setLoading(true);
      await api.loginCosmos(type);
      console.log("hide login");
      setShowLogin(false);
    } catch (error: any) {
      if (error.message) {
        toast(t(error.message));
      }
    } finally {
      setLoading(false);
    }
  }

  async function onTerraClick() {
    if (isTerra) {
      try {
        setLoading(true);
        connect(ConnectType.EXTENSION);
        let nonce = await api.getNonce();
        let sign = await signBytes(Buffer.from(nonce));
        let { customerInfo, token } = await api.post(
          "/customer/login-customer",
          {
            sign: JSON.stringify(sign.result),
            nonce,
            type: LoginType.Terra,
          }
        );
        setShowLogin(false);
        sessionStorage.setItem("token", token);
        return { customerInfo, token };
      } catch (error: any) {
        if (error.code === "customer_is_block") {
          toast(`${t("you_have_been_banned_due_to")} ${error.message}`);
        } else {
          if (error.message) {
            toast(t(error.message));
          }
        }
      } finally {
        setLoading(false);
      }
    } else {
      install(ConnectType.EXTENSION);
    }
  }
  const onWeb3AuthClick = async () => {
    sessionStorage.setItem("current_login_type", String(LoginType.Web3Auth));

    let token = await web3authService.connect();
    setShowLogin(false);
    sessionStorage.setItem("token", token);
  };

  const onSocialClick = async (accessToken: string, loginType: LoginType) => {
    try {
      await api.loginBySocial(accessToken, loginType);
      setShowLogin(false);
    } catch (err: any) {
      if (err.message) {
        toast(t(err.message));
      }
    }
  };

  if (modals.length > 0) {
    return <></>;
  }

  // @ts-ignore
  if (!window.getNetwork()) {
    return <></>;
  }

  if (isEmailClick)
    return (
      <EmailConnector
        setShowLogin={setShowLogin}
        type={"signin"}
        onBack={() => {
          setIsEmailClick(false);
        }}
        onDone={() => {
          setShowLogin(false);
        }}
      />
    );

  return (
    <div className="bg bg-contain px-1 w-[590px] max-w-full" id="auto-scale">
      <p className="text-4xl py-3 pt-6 text-outline w-full mx-auto">
        {t("welcome_message")}
      </p>
      {dayjs(date).isAfter(dayjs()) && !localStorage.getItem("tester") ? (
        <div className="bg-[#22253a] p-4">
          <div className="pt-12 pb-20">
            <div className="text-center mt-4 text-white text-2xl self-center justify-center text-center mx-auto">
              The Mainnet version will{" "}
              <span className="text-[#4FFF58]">be opened</span> in:
            </div>
            <div className="text-center mt-4 text-white text-xl self-center justify-center text-center mx-auto grid grid-cols-4 w-4/5 gap-4">
              {["days", "hours", "minutes", "seconds"].map(
                (e: any, idx: number) => {
                  return (
                    <div>
                      <div>
                        <div className="flex">
                          <div key={e} className="clock-container w-full">
                            {text[e]}
                          </div>
                          <span
                            className="inline-block self-center"
                            style={{
                              transform: "translateX(7px)",
                            }}
                          >
                            {idx !== 3 && ":"}
                          </span>
                        </div>
                        <div className="text-uppercase">{e}</div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-[#22253a] p-4">
          {maintainMessage !== "" && (
            <div className="flex">
              <p className="text-base text-outline-red">{maintainMessage}</p>
            </div>
          )}
          {loading ? (
            <div className="min-h-[250px] flex">
              <p className="text-center mt-4 text-[#636a95] text-xl self-center justify-center text-center mx-auto">
                {t("loading_message")}
              </p>
            </div>
          ) : (
            <>
              <div className="text-white text-xl">{t("login_panel_title")}</div>
              <div className="bg-[#15172A] rounded-lg flex-wrap w-[520px] mx-auto">
                <div className="flex gap-4 mx-center text-center justify-center mt-4 py-2">
                  <div className="relative">
                    {Config.MainnetUnsupportedChain.includes(LoginType.BSC) && (
                      <div className="disabled-login-button-text">
                        Coming soon
                      </div>
                    )}
                    <div
                      className={`button-logo-container ${
                        Config.MainnetUnsupportedChain.includes(
                          LoginType.BSC
                        ) && "disabled-login-button"
                      }`}
                    >
                      <div
                        className="button-logo"
                        onClick={async () =>
                          await onMetaMaskClick(LoginType.BSC)
                        }
                      >
                        <img
                          src={require("./images/bsc.png")}
                          className="w-8 h-8 object-contain"
                          alt="bsc"
                        />
                      </div>
                      <div>BNB Chain</div>
                    </div>
                  </div>

                  <div className="relative">
                    {Config.MainnetUnsupportedChain.includes(
                      LoginType.Avalanche
                    ) && (
                      <div className="disabled-login-button-text">
                        Coming soon
                      </div>
                    )}
                    <div
                      className={`button-logo-container ${
                        Config.MainnetUnsupportedChain.includes(
                          LoginType.Avalanche
                        ) && "disabled-login-button"
                      }`}
                    >
                      <div
                        className="button-logo"
                        onClick={async () =>
                          await onMetaMaskClick(LoginType.Avalanche)
                        }
                      >
                        <img
                          src={require("./images/avalanche.png")}
                          className="w-8 h-8 object-contain"
                          alt="avalanche"
                        />
                      </div>
                      <div>AVAX Network</div>
                    </div>
                  </div>

                  <div className="relative">
                    {Config.MainnetUnsupportedChain.includes(
                      LoginType.Okex
                    ) && (
                      <div className="disabled-login-button-text">
                        Coming soon
                      </div>
                    )}
                    <div
                      className={`button-logo-container ${
                        Config.MainnetUnsupportedChain.includes(
                          LoginType.Okex
                        ) && "disabled-login-button"
                      }`}
                    >
                      <div
                        className="button-logo"
                        onClick={async () =>
                          await onMetaMaskClick(LoginType.Okex)
                        }
                      >
                        <img
                          src={require("./images/okex.png")}
                          className="w-8 h-8 object-contain"
                          alt="okex"
                        />
                      </div>
                      <div>OKX Chain</div>
                    </div>
                  </div>

                  <div className="relative">
                    {Config.MainnetUnsupportedChain.includes(
                      LoginType.Aura
                    ) && (
                      <div className="disabled-login-button-text">
                        Coming soon
                      </div>
                    )}
                    <div
                      className={`button-logo-container ${
                        Config.MainnetUnsupportedChain.includes(
                          LoginType.Aura
                        ) && "disabled-login-button"
                      }`}
                    >
                      <div
                        className="button-logo"
                        onClick={async () => {
                          await onKeplrClick(LoginType.Aura);
                        }}
                      >
                        <img
                          src={require("./images/aura.png")}
                          className="w-8 h-8 object-contain"
                          alt="okex"
                        />
                      </div>
                      <div>Aura Network</div>
                    </div>
                  </div>
                  <div className="relative">
                    <div className={`button-logo-container`}>
                      <div
                        className="button-logo"
                        onClick={async () => {
                          await onWeb3AuthClick();
                        }}
                      >
                        <img
                          src={require("./images/web3auth.png")}
                          className="w-8 h-8 object-contain"
                          alt="okex"
                        />
                      </div>
                      <div>Web3Auth</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2 mb-3 text-red">
                {t("extension_will_be_installed")}
              </div>
              <div
                className="br"
                style={{
                  width: "410px",
                }}
              ></div>
              <div className="pt-2.5 text-xl text-white">OR</div>
              <div className="mt-3 grid grid-cols-1 gap-4 w-[330px] text-center mx-auto justify-center">
                <div
                  className="flex cursor-pointer gap-4 bg-white p-2 pl-3 w-full rounded-lg justify-center"
                  onClick={async () => {
                    try {
                      setLoading(true);
                      let res = await signInWithApple(setLoading);
                      let idToken = res.idToken;
                      await onSocialClick(idToken, LoginType.Apple);
                      setLoading(false);
                    } catch (err) {
                      setLoading(false);
                    }
                  }}
                >
                  <div className="bg-white">
                    <img
                      src={require("./images/icon-apple.png")}
                      alt="facebook"
                      className="w-8 h-8 object-contain"
                      style={{
                        filter: "brightness(0%)",
                      }}
                    />
                  </div>
                  <div className="text-black self-center">
                    Sign in with Apple
                  </div>
                </div>

                {/* <ReactFacebookLogin
                  cookie={true}
                  // @ts-ignore
                  appId={window.getFacebookAppId()}
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={async (res: any) => {
                    let accessToken = res.accessToken;
                    await onSocialClick(accessToken, LoginType.Facebook);
                    setLoading(false);
                  }}
                  onFailure={() => {
                    setLoading(false);
                  }}
                  render={(renderProps: any) => (
                    <div className="flex cursor-pointer gap-4 bg-[#4c74e7] p-2 pl-3 w-full rounded-lg"
                      onClick={() => {
                        setLoading(true);
                        renderProps.onClick();
                      }}
                    >
                      <div className="bg-[#4c74e7]">
                        <img
                          src={require("./images/icon-facebook.png")}
                          alt="facebook"
                          className="w-8 h-8"
                        />
                      </div>
                      <div className="text-white self-center">Login with Facebook</div>
                    </div>
                  )}
                /> */}

                <div
                  className="flex cursor-pointer gap-4 bg-[#c5272b] p-2 pl-3 w-full rounded-lg justify-center"
                  onClick={() => {
                    setIsEmailClick(true);
                  }}
                >
                  <div className="bg-[#c5272b]">
                    <img
                      src={require("./images/icon-email.png")}
                      alt="facebook"
                      className="w-8 h-8 object-contain"
                    />
                  </div>
                  <div className="text-white self-center">
                    Sign in with Email
                  </div>
                </div>

                <div
                  className="flex cursor-pointer gap-4 bg-[#51b995] p-2 pl-3 w-full rounded-lg justify-center"
                  onClick={async () => {
                    try {
                      setLoading(true);
                      let tokenGuest = localStorage.getItem("token-guest");
                      if (tokenGuest) {
                        sessionStorage.setItem("token", tokenGuest);
                        try {
                          // @ts-ignore
                          let version = window.getVersion();
                          console.log({ version });
                          version = version.web;
                          await api.post(
                            "/customer/get-customer-info",
                            {},
                            version
                          );
                          setShowLogin(false);
                        } catch (error) {
                          setShowLogin(true);
                          sessionStorage.clear();
                          localStorage.clear();
                        }
                      } else {
                        await api.loginAsGuest(setShowLogin);
                      }
                      setLoading(false);
                    } catch (err) {
                      setLoading(false);
                    }
                  }}
                >
                  <div className="bg-[#51b995]">
                    <img
                      src={require("./images/icon-guest.png")}
                      alt="facebook"
                      className="w-8 h-8 object-contain"
                    />
                  </div>
                  <div className="text-white self-center">Play as Guest</div>
                </div>
              </div>

              {/* <div className="button-logo-container">
                    <div
                      className="button-logo"
                      onClick={async () => {
                        setLoading(true);
                        let res = await signInWithGoogle(setLoading);
                        let credential = res._tokenResponse;
                        let accessToken = credential.oauthAccessToken;
                        await onSocialClick(accessToken, LoginType.Google);
                        setLoading(false);
                      }}
                    >
                      <img
                        src={require("./images/google.png")}
                        className="w-8 h-8 object-contain"
                        alt="google"
                      />
                    </div>
                    <div>
                      Google
                    </div>
                  </div> */}
            </>
          )}
          <p className="mt-4 text-[#474c6f]">
            {t("continue_agreed_with_our")}{" "}
            <a
              className="underline"
              href="https://whitepaper.monsterra.io/policy/terms-of-use"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "white",
              }}
            >
              {t("term_of_service")}
            </a>
          </p>
        </div>
      )}
      <p className="text-lg py-3 relative h-[40px] text-[#21243C]">
        <div className="absolute right-[20px] top-[3px]">
          {t("version")} {sso_version?.generalVersion}_
          {sso_version?.backend || 0}_{sso_version?.sso || 0}_
          {sso_version?.web || 0}
        </div>
      </p>
    </div>
  );
}
