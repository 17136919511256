import { Web3Auth } from "@web3auth/modal";
import api from "api";
import { LoginType } from "game-interface";
let web3auth: any = null;
async function connect() {
  init();
  if (!web3auth.connected) {
    await web3auth.initModal();
    await web3auth.connect();
  }

  let privateKey = `0x${await web3auth.provider.request({
    method: "eth_private_key",
  })}`;
  console.log("Private key", privateKey);
  let idToken = (await web3auth.getUserInfo()).idToken;
  let { customerInfo, token } = await api.post("/customer/login-customer", {
    sign: idToken,
    nonce: "none",
    type: LoginType.Web3Auth,
  });
  return token;
}
async function init() {
  if (web3auth) {
    return;
  }

  web3auth = new Web3Auth({
    // @ts-ignore
    clientId: window.getWeb3authClientId(),
    chainConfig: {
      chainNamespace: "eip155",
    },
  });
}
const web3authService = {
  connect,
  init,
};
export default web3authService;
